/*            Navigation menu                */
.nav-pills {
    > li + li {
        margin-left: 0;
    }
    > li > a {
        border: 1px solid $info-color;
        border-radius: 0;
        color: $info-color;
        margin-left: -1px;
        
        &:hover,
        &:focus{
            background-color: #F5F5F5;
        }
    }
    > li.active > a,
    > li.active > a:hover,
    > li.active > a:focus {
        background-color: $info-color;
        color: #FFFFFF;
    }    
    > li:first-child > a{
        border-radius: 4px 0 0 4px;
        margin: 0;
    }
    > li:last-child > a{
        border-radius: 0 4px 4px 0;
    }

}

.pagination > li.disabled{
    opacity: .4;
}

.pagination.pagination-no-border > li > a,
.pagination.pagination-no-border > li > span{
    border: 0;
}
.pagination > li > a, 
.pagination > li > span, 
.pagination > li:first-child > a, 
.pagination > li:first-child > span, 
.pagination > li:last-child > a,
.pagination > li:last-child > span{
    border-radius: 50%;
    margin: 0 2px;
    color: $default-states-color;
}
.pagination > li.active > a, 
.pagination > li.active > span, 
.pagination > li.active > a:hover, 
.pagination > li.active > span:hover, 
.pagination > li.active > a:focus, 
.pagination > li.active > span:focus {
    background-color: $info-color;
    border: 0;
    color: #FFFFFF;
    padding: 7px 13px;
}

.nav-pills-blue{
    > li.active {
        > a,
        > a:hover,
        > a:focus{
           background-color: $color-blue; 
        }
    }
}

.pagination-blue{
    > li.active{
        a,
        a:hover,
        a:focus,
        span,
        span:hover,
        span:focus{
            background-color: $color-blue;
        }
    }
}

.nav-pills-azure{
    > li.active {
        > a,
        > a:hover,
        > a:focus{
           background-color: $color-azure; 
        }
    }
}

.pagination-azure{
    > li.active{
        a,
        a:hover,
        a:focus,
        span,
        span:hover,
        span:focus{
            background-color: $color-azure;
        }
    }
}

.nav-pills-green{
    > li.active {
        > a,
        > a:hover,
        > a:focus{
           background-color: $color-green; 
        }
    }
}

.pagination-green{
    > li.active{
        a,
        a:hover,
        a:focus,
        span,
        span:hover,
        span:focus{
            background-color: $color-green;
        }
    }
}

.nav-pills-orange{
    > li.active {
        > a,
        > a:hover,
        > a:focus{
           background-color: $color-orange; 
        }
    }
}

.pagination-orange{
    > li.active{
        a,
        a:hover,
        a:focus,
        span,
        span:hover,
        span:focus{
            background-color: $color-orange;
        }
    }
}

.nav-pills-red{
    > li.active {
        > a,
        > a:hover,
        > a:focus{
           background-color: $color-red; 
        }
    }
}

.pagination-red{
    > li.active{
        a,
        a:hover,
        a:focus,
        span,
        span:hover,
        span:focus{
            background-color: $color-red;
        }
    }
}

.nav-pills-blue > li > a {
    @include pill-style($color-blue);
}
.nav-pills-azure > li > a {
    @include pill-style($color-azure);
}
.nav-pills-green > li > a {
    @include pill-style($color-green);
}
.nav-pills-orange > li > a {
    @include pill-style($color-orange);
}
.nav-pills-red > li > a {
    @include pill-style($color-red);
}

.nav-text, 
.nav-icons{
    padding:  $padding-default-vertical 0 0;
    
    > li > a{
        display: block;
        padding: 0px $padding-base-horizontal;
        color: $dark-gray;
        text-align: center;
        @include opacity(0.8);
        
        &:hover,
        &:focus{
            background-color: $transparent-bg;
            @include opacity(1);
        }
    }
    > li:first-child a{
        padding-left: 0;
    }
    > li.active a{
       color: $info-color;  
    }
}

.nav-icons > li{
    display: inline-block;
     > a{
        padding: 0 10px;
        margin-bottom: 10px;
    }
    > a i{
        font-size: $font-size-h4;
        margin-bottom: 10px;
        width: $font-size-h4;
    }
}

.nav-icons.nav-stacked > li{
    display: block;
    > a {
        margin-bottom: 20px;
    }
}

.nav-blue > li.active a{
    color: $primary-color;
}
.nav-azure > li.active a{
    color: $info-color;
}
.nav-green > li.active a{
    color: $success-color;
}
.nav-orange > li.active a{
    color: $warning-color;
}
.nav-red > li.active a{
    color: $danger-color;
}

.nav-text{
    margin: $margin-bottom;
    
    > li > a{
        font-size: $font-size-h6;
        text-transform: uppercase;
        padding: 3px 0;
        text-align: left;
        font-weight: $font-weight-semi;
        
    }
    > li:first-child > a{
        padding-top: 0;
    }
    h4{
        margin-top: 0;
    }
}

.nav-text:not(.nav-stacked){
    > li{
        display: inline-block;
    }
    > li > a{
        margin-right: 15px;
    }
}

.tab-pane{
    padding: 15px 0;
}

.nav-container{
    display: block;
    width: 100%;
    text-align: center;
}
.content-full-width{
    .nav-tabs{
        li:first-child{
            padding-left: $padding-default-horizontal;    
        }
    }
    .tab-pane{
        padding: $padding-default-horizontal;
    }
}

